import React from 'react';

function Footer() {
  return (
    <footer className="bg-white shadow-md fixed bottom-0 left-0 right-0">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <div>&copy; 2024 WPLMS</div>
        <div>
          <a href="https://www.wplms.cn/#support" className="hover:text-blue-600">联系我们</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;