import axios from 'axios';

const API_KEY = 'sk-ceutbtwhneymjlxpekdysdjoltthobxnbziszrbuhafquufm';
const API_URL = 'https://api.siliconflow.cn/v1/chat/completions';

const api = axios.create({
  timeout: 60000,
  headers: {
    'Authorization': `Bearer ${API_KEY}`,
    'Content-Type': 'application/json'
  }
});

const retryOperation = async (operation, retries = 3, delay = 2000) => {
  try {
    return await operation();
  } catch (error) {
    if (retries > 0) {
      await new Promise(resolve => setTimeout(resolve, delay));
      return retryOperation(operation, retries - 1, delay * 2);
    }
    throw error;
  }
};

const generateOutline = async (title, language) => {
  const systemContent = language === 'chinese' 
    ? `您是一个有用的助手，可以生成多级课程大纲。请用中文回答，并将大纲格式化为 JSON 数组，其中每个项目要么是字符串（主要主题），要么是数组（子主题）。`
    : `You are a helpful assistant that generates multi-level course outlines. Please format the outline as a JSON array where each item is either a string (for main topics) or an array (for subtopics).`;

  const operation = async () => {
    const response = await api.post(API_URL, {
      model: "Qwen/Qwen2-7B-Instruct",
      messages: [
        { role: "system", content: systemContent },
        { role: "user", content: language === 'chinese' ? `请为以下主题生成多级课程大纲：${title}` : `Generate a multi-level course outline for: ${title}` }
      ]
    });

    const outlineText = response.data.choices[0].message.content;
    return JSON.parse(outlineText);
  };

  try {
    return await retryOperation(operation);
  } catch (error) {
    console.error('Error generating outline:', error);
    throw new Error('生成大纲失败，请稍后重试。');
  }
};

const generateContent = async (title, topic, outline, language) => {
  const systemContent = language === 'chinese'
    ? `您是一位资深的教育专家和课程设计师，能够严格按照给定的课程大纲生成精确的课程内容。请确保内容的结构和标题与大纲完全一致，不要添加、省略或更改任何标题。使用Markdown格式输出。`
    : `You are an experienced education expert and curriculum designer capable of generating precise course content strictly following the given course outline. Ensure that the content structure and headings exactly match the outline, without adding, omitting, or altering any headings. Please output in Markdown format.`;

  let userContent;
  if (topic === "课程简介") {
    // 课程简介的提示词保持不变
    userContent = language === 'chinese'
      ? `请为课程"${title}"生成一个全面而简洁的课程简介，包括以下要素，并确保每个要素都明确标注：
1. 课程目标：概述学习者完成课程后将获得的主要技能或知识。
2. 课程概述：简要介绍课程的整体结构，确保完全基于以下课程大纲，并准确反映其结构：
${JSON.stringify(outline, null, 2)}
3. 主要内容：列举课程涵盖的关键主题，必须与课程大纲中的主要章节完全对应，保持相同的编号和层级结构。
4. 学习成果：描述学习者完成课程后预期达到的具体成果。
5. 适用对象：说明该课程的目标学习群体。

请确保课程简介的内容完全符合并涵盖课程大纲中的所有主要部分，同时保持简洁明了。严格使用Markdown格式输出，并保持标题的层级结构。`
      : `Please generate a comprehensive yet concise course introduction for "${title}", including the following elements, ensuring each element is clearly labeled:
1. Course Objectives: Outline the main skills or knowledge learners will acquire upon completion.
2. Course Overview: Briefly introduce the overall structure of the course, ensuring it's fully based on and accurately reflects the structure of the following course outline:
${JSON.stringify(outline, null, 2)}
3. Main Content: List the key topics covered in the course, ensuring they exactly correspond to the main chapters in the course outline, maintaining the same numbering and hierarchical structure.
4. Learning Outcomes: Describe the specific outcomes expected for learners after completing the course.
5. Target Audience: Indicate the intended learning group for this course.

Please ensure that the course introduction fully aligns with and covers all major sections in the course outline while remaining concise and clear. Strictly use Markdown format for output and maintain the heading hierarchy.`;
  } else {
    // 修改这部分以生成特定标题的内容
    userContent = language === 'chinese'
      ? `你是一位经验丰富的教育工作者和教材编写专家。你的任务是为课程"${title}"中的特定主题"${topic}"生成高质量的课程材料。请严格遵循以下规则：

1. 整体课程大纲如下，请familiarize yourself with it：
${JSON.stringify(outline, null, 2)}

2. 你只需要生成"${topic}"这个特定主题的内容。不要生成其他主题的内容。
3. 确保生成的内容结构与大纲中"${topic}"的部分完全一致，使用正确的Markdown标题级别（如 # , ## , ### 等）。
4. 对于"${topic}"下的每个子标题（如果有），提供详细、准确和相关的内容。
5. 如果"${topic}"在大纲中没有子标题，请为其生成适当的段落内容。
6. 使用Markdown格式编写所有内容，包括列表、强调、链接等。
7. 包含相关的例子、解释或练习，但不要偏离"${topic}"的范围。

请开始生成"${topic}"的具体内容，确保完全符合大纲结构和要求。只输出"${topic}"相关的内容，不要包括其他主题。`
      : `As an experienced educator and textbook author, your task is to generate high-quality course material for the specific topic "${topic}" in the course "${title}". Please strictly adhere to the following rules:

1. The overall course outline is as follows, please familiarize yourself with it:
${JSON.stringify(outline, null, 2)}

2. You only need to generate content for the specific topic "${topic}". Do not generate content for other topics.
3. Ensure the generated content's structure exactly matches the "${topic}" section in the outline, using the correct Markdown heading levels (e.g., # , ## , ### , etc.).
4. For each subheading under "${topic}" (if any), provide detailed, accurate, and relevant content.
5. If "${topic}" has no subheadings in the outline, generate appropriate paragraph content for it.
6. Use Markdown formatting for all content, including lists, emphasis, links, etc.
7. Include relevant examples, explanations, or exercises, but do not deviate from the scope of "${topic}".

Please begin generating the specific content for "${topic}", ensuring full compliance with the outline structure and requirements. Only output content related to "${topic}", do not include other topics.`;
  }

  const operation = async () => {
    const response = await api.post(API_URL, {
      model: "Qwen/Qwen2-7B-Instruct",
      messages: [
        { role: "system", content: systemContent },
        { role: "user", content: userContent }
      ]
    });

    return response.data.choices[0].message.content;
  };

  try {
    return await retryOperation(operation);
  } catch (error) {
    console.error('Error generating content:', error);
    throw new Error('生成内容失败，请稍后重试。');
  }
};

export { generateOutline, generateContent };