import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import authService from '../services/authService';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const user = authService.getCurrentUser();

  const handleLogout = () => {
    authService.logout();
    navigate('/welcome');  // 修改这里，注销后导航到欢迎页面
  };

  return (
    <header className="bg-white shadow-md fixed top-0 left-0 right-0 z-50">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <div className="text-xl font-bold">WPLMS - 教师备课系统</div>
        <nav className="hidden md:block">
          <ul className="flex space-x-4">
            <li><Link to="/" className="hover:text-blue-600">首页</Link></li>
            <li><a href="https://www.wplms.cn/doc.html" className="hover:text-blue-600">使用帮助</a></li>
            <li><a href="https://www.wplms.cn/#support" className="hover:text-blue-600">联系我们</a></li>
            {user ? (
              <>
                <li><Link to="/dashboard" className="hover:text-blue-600">会员仪表盘</Link></li>
                <li><button onClick={handleLogout} className="hover:text-blue-600">注销</button></li>
              </>
            ) : (
              <li><Link to="/login" className="hover:text-blue-600">登录</Link></li>
            )}
          </ul>
        </nav>
        <div className="md:hidden">
          <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
            {isMenuOpen ? (
              <XIcon className="h-6 w-6" />
            ) : (
              <MenuIcon className="h-6 w-6" />
            )}
          </button>
        </div>
      </div>
      {isMenuOpen && (
        <div className="md:hidden">
          <ul className="px-4 py-2">
            <li><Link to="/" className="block py-2 hover:text-blue-600">首页</Link></li>
            <li><a href="https://www.wplms.cn/docs" className="block py-2 hover:text-blue-600">使用帮助</a></li>
            <li><a href="https://www.wplms.cn/#support" className="block py-2 hover:text-blue-600">联系我们</a></li>
            {user ? (
              <>
                <li><Link to="/dashboard" className="block py-2 hover:text-blue-600">会员仪表盘</Link></li>
                <li><button onClick={handleLogout} className="block py-2 hover:text-blue-600">注销</button></li>
              </>
            ) : (
              <li><Link to="/login" className="block py-2 hover:text-blue-600">登录</Link></li>
            )}
          </ul>
        </div>
      )}
    </header>
  );
}

export default Header;