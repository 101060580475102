import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import rehypeHighlight from 'rehype-highlight';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'github-markdown-css/github-markdown.css';
import 'highlight.js/styles/github.css';
import 'katex/dist/katex.min.css';
import { RefreshIcon } from '@heroicons/react/outline';

function CourseContent({ content, onRegenerate, isLoading }) {
  return (
    <div className="bg-white p-4 rounded shadow relative">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">课程内容</h2>
        <div>
          <button
            onClick={onRegenerate}
            className="mr-2 p-2 bg-blue-500 text-white rounded flex items-center hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            disabled={isLoading}
          >
            <RefreshIcon className="h-5 w-5 mr-1" />
            重新生成
          </button>
        </div>
      </div>
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-10">
          <div className="text-lg font-semibold text-gray-700">生成中...</div>
        </div>
      )}
      <div className="markdown-body prose max-w-none">
        <ReactMarkdown
          remarkPlugins={[remarkMath]}
          rehypePlugins={[rehypeRaw, rehypeSanitize, rehypeHighlight, rehypeKatex]}
        >
          {content}
        </ReactMarkdown>
      </div>
    </div>
  );
}

export default CourseContent;