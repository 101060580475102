import React from 'react';

function CourseOutline({ outline, onSelectTopic }) {
  const renderOutlineItem = (item, index, prefix = '') => {
    if (typeof item === 'string') {
      const itemNumber = prefix ? `${prefix}` : `${index + 1}`;
      return (
        <li key={index} className="my-1">
          <button
            onClick={() => onSelectTopic(item)}
            className="text-blue-600 hover:text-blue-800 hover:underline transition-colors duration-200"
          >
            {itemNumber}. {item}
          </button>
        </li>
      );
    } else if (Array.isArray(item)) {
      const subPrefix = prefix ? `${prefix}.` : `${index + 1}.`;
      return (
        <li key={index} className="ml-4 my-1">
          <ul className="list-none">
            {item.map((subItem, subIndex) => renderOutlineItem(subItem, subIndex, `${subPrefix}${subIndex + 1}`))}
          </ul>
        </li>
      );
    }
  };

  return (
    <div className="bg-white p-4 rounded shadow sticky top-16 max-h-[calc(100vh-5rem)] overflow-y-auto">
      <h2 className="text-xl font-bold mb-4">课程大纲</h2>
      <ul className="list-none">{outline.map((item, index) => renderOutlineItem(item, index))}</ul>
    </div>
  );
}

export default CourseOutline;