import React from 'react';
import { Link } from 'react-router-dom';

function WelcomePage() {
  return (
    <div className="flex flex-col min-h-screen">
      <div 
        className="w-full h-[450px] bg-cover bg-center"
        style={{
          backgroundImage: `url('https://www.wplms.cn/wp-content/uploads/2023/07/architects-talking-in-the-office-1024x682.webp')`
        }}
      ></div>
      <div className="flex-grow flex items-center justify-center bg-gray-100">
        <div className="text-center px-4 py-8">
          <h1 className="text-3xl md:text-4xl font-bold mb-4">欢迎大家使用WPLMS系统</h1>
          <p className="text-xl md:text-2xl mb-8">一键生成课程大纲和课程内容</p>
          <Link 
            to="/login" 
            className="bg-blue-500 text-white px-6 py-3 rounded-md text-lg font-semibold hover:bg-blue-600 transition duration-300"
          >
            登录
          </Link>
        </div>
      </div>
    </div>
  );
}

export default WelcomePage;