import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CourseOutline from '../components/CourseOutline';
import CourseContent from '../components/CourseContent';
import HistoryDropdown from '../components/HistoryDropdown';
import { generateOutline, generateContent } from '../services/api';
import authService from '../services/authService';

function Home() {
  const [courseTitle, setCourseTitle] = useState('');
  const [language, setLanguage] = useState('chinese');
  const [outline, setOutline] = useState([]);
  const [content, setContent] = useState('');
  const [history, setHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [generatedContents, setGeneratedContents] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    setIsAuthenticated(authService.isAuthenticated());
    
    // 从本地存储加载历史记录
    const savedHistory = localStorage.getItem('courseHistory');
    if (savedHistory) {
      setHistory(JSON.parse(savedHistory));
    }
  }, []);

  // 保存历史记录到本地存储
  useEffect(() => {
    localStorage.setItem('courseHistory', JSON.stringify(history));
  }, [history]);

  const handleGenerateOutline = async () => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }

    setIsLoading(true);
    try {
      const generatedOutline = await generateOutline(courseTitle, language);
      setOutline(generatedOutline);
      
      // 生成课程简介
      const introduction = await generateContent(courseTitle, "课程简介", generatedOutline, language);
      setContent(introduction);
      
      // 将生成的简介存储到 generatedContents 中
      setGeneratedContents({
        "课程简介": introduction
      });
      
      // 添加到历史记录
      setHistory(prevHistory => {
        const newHistory = [{ title: courseTitle, outline: generatedOutline, introduction }, ...prevHistory];
        return newHistory.slice(0, 10); // 只保留最近的10条记录
      });
    } catch (error) {
      console.error('Error generating outline:', error);
      setContent('生成大纲时出错。请重试。');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectTopic = async (topic) => {
    setIsLoading(true);
    try {
      // 检查是否已经生成过该主题的内容
      if (generatedContents[topic]) {
        setContent(generatedContents[topic]);
      } else {
        const generatedContent = await generateContent(courseTitle, topic, outline, language);
        setContent(generatedContent);
        // 将新生成的内容存储到 generatedContents 中
        setGeneratedContents(prev => ({
          ...prev,
          [topic]: generatedContent
        }));
      }
    } catch (error) {
      console.error('Error generating topic content:', error);
      setContent('生成内容时出错。请重试。');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectHistory = (item) => {
    setCourseTitle(item.title);
    setOutline(item.outline);
    setContent(item.introduction);
    setGeneratedContents({
      "课程简介": item.introduction
    });
  };

  return (
    <div className="container mx-auto p-4">
      <div className="mb-4">
        <input
          type="text"
          value={courseTitle}
          onChange={(e) => setCourseTitle(e.target.value)}
          placeholder="输入课程标题"
          className="w-full p-2 border rounded"
        />
        <select
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
          className="mt-2 p-2 border rounded"
        >
          <option value="chinese">中文</option>
          <option value="english">English</option>
        </select>
        <button
          onClick={handleGenerateOutline}
          className="mt-2 p-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          disabled={isLoading}
        >
          生成课程
        </button>
      </div>
      {isAuthenticated && <HistoryDropdown history={history} onSelect={handleSelectHistory} />}
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-1/4 mb-4 md:mb-0">
          <CourseOutline outline={outline} onSelectTopic={handleSelectTopic} />
        </div>
        <div className="w-full md:w-3/4 md:pl-4">
          <CourseContent
            courseTitle={courseTitle}
            content={content}
            onRegenerate={handleGenerateOutline}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
}

export default Home;