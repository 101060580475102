const authService = {
  login(username, password) {
    if (username === 'china' && password === 'hichina') {
      const user = { username: 'admin', email: 'vip@vpndc.com' };
      localStorage.setItem('user', JSON.stringify(user));
      return user;
    }
    return null;
  },

  logout() {
    localStorage.removeItem('user');
  },

  getCurrentUser() {
    const userStr = localStorage.getItem('user');
    if (userStr) {
      return JSON.parse(userStr);
    }
    return null;
  },

  isAuthenticated() {
    return this.getCurrentUser() !== null;
  }
};

export default authService;