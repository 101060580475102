import React from 'react';

function HistoryDropdown({ history, onSelect }) {
  return (
    <div className="mb-4">
      <select
        onChange={(e) => onSelect(history[e.target.value])}
        className="w-full p-2 border rounded"
      >
        <option value="">选择历史课程</option>
        {history.map((item, index) => (
          <option key={index} value={index}>
            {item.title}
          </option>
        ))}
      </select>
    </div>
  );
}

export default HistoryDropdown;